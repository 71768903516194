import React, { useRef, useEffect } from 'react';
import { makeStyles, Paper, IconButton } from '@material-ui/core';
import ReplayIcon from '@material-ui/icons/Replay';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import Title from '../../components/Title';

import './css/style.css';
import CarLeft from './img/car-left.svg';
import CarRight from './img/car-right.svg';
import CircleLeft from './img/circle-left.svg';
import CircleRight from './img/circle-right.svg';
import SquareLeft from './img/square-left.svg';
import SquareRight from './img/square-right.svg';

const useStyles = makeStyles((theme) => ({
	menu: {
		backgroundColor: 'black',
		opacity: '0.5',
		position: 'absolute',
		width: '100%',
		height: '100vh',
		textAlign: 'center',
		alignItems: 'center',
		justifyContent: 'center',
		display: 'flex',
		webkitBoxAlign: 'center',
		msFlexAlign: 'center',
		webkitBoxPack: 'center',
		msFlexPack: 'center',
	},
}));

//Game Settings
export const gs = {
	windowWidth: window.innerWidth,
	windowHeight: window.innerHeight - 8,

	dpr: window.devicePixelRatio,
	dprWindowWidth: window.innerWidth * window.devicePixelRatio,
	dprWindowHeight: (window.innerHeight - 8) * window.devicePixelRatio,

	rWidth: 0,
	rWidthDouble: 0,
	rWidthTriple: 0,
	rWidthHalf: 0,
	rWidthQrtr: 0,
	obstacleWidth: 0,

	UIEnabled: false,
	isRunning: false,

	point: 0,
	speed: 0,
	spawnSpeed: 0,
	spawnInterval: 1000,

	startTime: 0,
	pauseTime: 0,
	resumeTime: 0,
	endTime: 0,

	fogSettings: {
		idx: 0,
		density: 20,
		minSize: 15,
		maxSize: 20,
		minSpeed: 10,
		maxSpeed: 20,
		gravity: 1,
		maxLife: 100,
	},

	explosionSettions: {
		density: 40,
		minSize: 10,
		maxSize: 20,
		minSpeed: 20,
		maxSpeed: 40,
	},

	pauseDelay: 2500,
	startTimer: 0,

	cars: [],
	circles: {},
	squares: {},
	fogParticles: {},
	explosions: [],
};

export default function Cars() {
	const classes = useStyles();
	const menuRef = useRef(null);
	const startButtonRef = useRef(null);
	const canvasRef = useRef(null);

	useEffect(() => {
		const canvas = canvasRef.current;
		const ctx = canvas.getContext('2d');

		//Elements
		const circleLeft = document.getElementById('circle-left');
		const circleRight = document.getElementById('circle-right');
		const squareLeft = document.getElementById('square-left');
		const squareRight = document.getElementById('square-right');
		const carLeft = document.getElementById('car-left');
		const carRight = document.getElementById('car-right');

		//Utiliy Controls

		const randomObstacle = () => {
			const idx = randInt(0, 8, true);

			if ([0, 1, 2, 3].includes(idx))
				return {
					isCircle: true,
					obst: [0, 1].includes(idx) ? circleLeft : circleRight,
					x: gs.rWidth * idx,
				};
			if ([4, 5, 6, 7].includes(idx))
				return {
					isCircle: false,
					obst: [4, 5].includes(idx) ? squareLeft : squareRight,
					x: gs.rWidth * (idx - 4),
				};

			return null;
		};

		const touchXY = (e) => {
			if (!gs.UIEnabled) return;

			let { pageX, pageY } = e.targetTouches[0];
			let touchX = pageX - canvas.offsetLeft;
			let touchY = pageY - canvas.offsetTop;

			if (e.targetTouches.length > 1) {
				pageX = e.targetTouches[1].pageX;
				touchX = pageX - canvas.offsetLeft;
				touchY = pageY - canvas.offsetTop;
			}

			console.log(`${touchX * gs.dpr},${touchX * gs.dpr} `);
			if (touchY * gs.dpr < 200 && touchX * gs.dpr < 200) pauseGame(null, true);
			else touchX * gs.dpr < gs.rWidthDouble ? gs.cars[0].toggle() : gs.cars[1].toggle();
		};

		const updatePoint = () => {
			gs.point++;
			console.log('Point:' + gs.point);

			gs.speed += 0.2;
		};

		const gameOver = () => {
			pauseGame(null, false);
			disableUI();
			gs.endTime = new Date().getTime();
			console.log('Game Over! Point:' + gs.point);
		};

		const distance = (x1, y1, x2, y2) => {
			const xDistance = x2 - x1;
			const yDistance = y2 - y1;

			return Math.sqrt(Math.pow(xDistance, 2) + Math.pow(yDistance, 2));
		};

		//Utiliy Controls
		const enableUI = () => (gs.UIEnabled = true);
		const disableUI = () => (gs.UIEnabled = false);
		const resumeGame = () => {
			startStartTimer(null);
			menuRef.current.style.display = 'none';
			setTimeout(() => {
				gs.isRunning = true;
				gs.resumeTime = new Date().getTime();
			}, gs.pauseDelay);
		};
		const pauseGame = (e, immediate = true) => {
			gs.isRunning = false;
			gs.pauseTime = new Date().getTime();
			if (immediate) menuRef.current.style.display = 'flex';
			else
				setTimeout(() => {
					menuRef.current.style.display = 'flex';
				}, gs.pauseDelay);
		};
		const startStartTimer = (value) => {
			if (value === null) gs.startTimer = 3;

			setTimeout(() => {
				gs.startTimer -= 1;
				if (value === 0) return;
				startStartTimer(gs.startTimer - 1);
			}, gs.pauseDelay / 3);
		};

		// Returns an random integer, positive or negative
		// between the given value
		const randInt = (min, max, positive) => {
			let num;
			if (!positive) {
				num = Math.floor(Math.random() * max) - min;
				num *= Math.floor(Math.random() * 2) === 1 ? 1 : -1;
			} else {
				num = Math.floor(Math.random() * max) + min;
			}

			return num;
		};

		//Classes
		class Car {
			constructor(ctx, id, isLeft, img, x, y, width, height) {
				this.ctx = ctx;
				this.id = id;
				this.isLeft = isLeft;
				this.isMOving = false;
				this.x = x;
				this.y = y;
				this.velocity = {
					x: 0,
					y: 0,
					angle: 0,
				};
				this.width = width;
				this.height = height;
				this.angle = 0;
				this.img = img;
			}

			toggle() {
				if (!gs.isRunning) return;

				if (this.isLeft) {
					//move right
					this.isLeft = false;
					this.velocity.angle = gs.speed / 4;
					this.velocity.x = gs.speed;
				} else {
					//move left
					this.isLeft = true;
					this.velocity.angle = -gs.speed / 4;
					this.velocity.x = -gs.speed;
				}
			}

			update() {
				if (!gs.isRunning) return;

				if (this.id === 0 && (this.x < 0 || this.x > gs.rWidth)) {
					this.velocity.x = 0;
					this.x = this.isLeft === true ? 0 : gs.rWidth;
				}
				if (this.id === 1 && (this.x < gs.rWidthDouble || this.x > gs.rWidthTriple)) {
					this.velocity.x = 0;
					this.x = this.isLeft === true ? gs.rWidthDouble : gs.rWidthTriple;
				}

				this.x += this.velocity.x;
				this.y += this.velocity.y;
				this.angle += this.velocity.angle;

				if (this.angle < 1 && this.angle > -1) {
					this.velocity.angle = 0;
					this.angle = 0;
				} else if (this.angle > 30) {
					this.velocity.angle = -gs.speed / 4;
				} else if (this.angle < -30) {
					this.velocity.angle = gs.speed / 4;
				}
			}

			draw() {
				this.ctx.beginPath();
				this.ctx.save();
				this.ctx.translate(this.x + this.width / 2, this.y + this.height / 2);
				this.ctx.rotate((this.angle * Math.PI) / 180);
				this.ctx.fillStyle = 'red';
				this.ctx.drawImage(this.img, -this.width / 2, -this.height / 2, this.width, this.height);
				this.ctx.restore();
				this.ctx.closePath();
			}
		}

		class Circle {
			constructor(ctx, id, img, x, y, width, height) {
				this.ctx = ctx;
				this.id = id;
				this.x = x;
				this.y = y;
				this.width = width;
				this.height = height;
				this.img = img;
				this.isVisible = true;
			}

			blink() {
				let count = 1;
				let intrvl = setInterval(() => {
					this.isVisible = !this.isVisible;
					count++;
					if (count >= 9) clearInterval(intrvl);
				}, 250);
			}

			update() {
				if (!gs.isRunning) return;

				for (let i in gs.cars) {
					let dist = distance(this.x, this.y, gs.cars[i].x, gs.cars[i].y);
					if (dist - (this.height / 2 + gs.cars[i].height / 2) <= 0) {
						//circle collided with a car, add point
						updatePoint();
						delete gs.circles[this.id];
						delete this;
					}
				}

				if (this.y >= gs.cars[1].y + 100 * gs.dpr) {
					console.log('Circle Missed');
					gameOver();
					this.blink();
				}

				this.y += gs.speed;
			}

			draw() {
				if (!this.isVisible) return;

				this.ctx.beginPath();
				this.ctx.save();
				this.ctx.drawImage(this.img, this.x, this.y, this.width, this.height);
				this.ctx.restore();
				this.ctx.closePath();
			}
		}
		class Square {
			constructor(ctx, id, img, x, y, width, height) {
				this.ctx = ctx;
				this.id = id;
				this.x = x;
				this.y = y;
				this.width = width;
				this.height = height;
				this.img = img;
			}

			update() {
				if (!gs.isRunning) return;

				for (let i in gs.cars) {
					let dist = distance(this.x, this.y, gs.cars[i].x, gs.cars[i].y);

					if (dist - (this.height / 2 + gs.cars[i].height / 2) + 50 <= 0) {
						console.log('Square Collided');
						//create explosion
						let isL = this.x < gs.rWidthDouble ? true : false;
						let expl = new Explosion(this.ctx, this.x, this.y, isL);
						gs.explosions.push(expl);
						gameOver();
						delete gs.squares[this.id];
						delete this;
					}
				}

				if (gs.dprWindowHeight - this.y <= 0) {
					//remove this obstacle from the squares-object and delete it
					delete gs.squares[this.id];
					delete this;
				}

				this.y += gs.speed;
			}

			draw() {
				this.ctx.save();
				this.ctx.beginPath();
				this.ctx.drawImage(this.img, this.x, this.y, this.width, this.height);
				this.ctx.restore();
				this.ctx.closePath();
			}
		}
		//fog particle
		class FogParticle {
			constructor(ctx, id, x, y) {
				this.ctx = ctx;
				this.id = id;
				this.x = x;
				this.y = y;
				this.xv = randInt(gs.fogSettings.minSpeed, gs.fogSettings.maxSpeed, false);
				this.yv = randInt(gs.fogSettings.minSpeed, gs.fogSettings.maxSpeed, true);
				this.size = randInt(gs.fogSettings.minSize, gs.fogSettings.maxSize, true);
			}

			update() {
				this.x += this.xv;
				this.y += this.yv;

				// Adjust for gravity
				this.yv += gs.fogSettings.gravity;

				// Age the particle
				this.life++;

				// If Particle is old, remove it
				if (this.life >= gs.fogSettings.maxLife) {
					delete gs.fogParticles[this.id];
				}
			}

			draw() {
				this.ctx.beginPath();
				this.ctx.fillStyle = '#ffffff';
				this.ctx.rect(this.x, this.y, this.size, this.size);
				this.ctx.closePath();
				this.ctx.fill();
			}
		}
		// Particle
		class ExplosionParticle {
			constructor(x, y, isL, isWhite) {
				this.x = x + gs.rWidthQrtr;
				this.y = y + gs.rWidthQrtr;
				this.xv = randInt(gs.explosionSettions.minSpeed, gs.explosionSettions.maxSpeed, false);
				this.yv = randInt(gs.explosionSettions.minSpeed, gs.explosionSettions.maxSpeed, false);
				this.size = randInt(gs.explosionSettions.minSize, gs.explosionSettions.maxSize, true);
				this.r = isWhite === false ? (isL === true ? 240 : 0) : 255;
				this.g = isWhite === false ? (isL === true ? 58 : 170) : 255;
				this.b = isWhite === false ? (isL === true ? 98 : 195) : 255;
			}
		}
		// Explosion
		class Explosion {
			constructor(ctx, x, y, isL) {
				this.ctx = ctx;
				this.particles = [];
				for (let i = 0; i < gs.explosionSettions.density; i++) {
					let isWhite = i % 3 === 0 ? true : false;
					this.particles.push(new ExplosionParticle(x, y, isL, isWhite));
				}
			}

			update() {
				if (this.particles.length === 0) return;

				const particlesAfterRemoval = this.particles.slice();
				for (let ii = 0; ii < this.particles.length; ii++) {
					const particle = this.particles[ii];

					// Check particle size
					// If 0, remove
					if (particle.size <= 0) {
						particlesAfterRemoval.splice(ii, 1);
						continue;
					}

					// Update
					particle.x += particle.xv;
					particle.y += particle.yv;
					particle.size -= 0.1;
				}

				this.particles = particlesAfterRemoval;
			}

			draw() {
				if (this.particles.length === 0) return;

				for (let ii = 0; ii < this.particles.length; ii++) {
					const particle = this.particles[ii];

					if (particle.size <= 0) continue;

					this.ctx.beginPath();
					this.ctx.rect(particle.x, particle.y, particle.size, particle.size);
					this.ctx.closePath();
					this.ctx.fillStyle = `rgb(${particle.r},${particle.g},${particle.b})`;
					this.ctx.fill();
				}
			}
		}

		//Implimentations
		const addObstacle = () => {
			if (gs.spawnInterval > 400) gs.spawnInterval -= gs.spawnSpeed;
			setTimeout(() => {
				addObstacle();
			}, gs.spawnInterval);

			let id = Math.random();
			let obstacle = randomObstacle();

			if (!gs.isRunning || !obstacle) {
				return;
			} else if (obstacle.isCircle) {
				gs.circles[id] = new Circle(
					ctx,
					id,
					obstacle.obst,
					obstacle.x + gs.rWidthQrtr,
					-gs.obstacleWidth,
					gs.obstacleWidth,
					gs.obstacleWidth
				);
			} else {
				gs.squares[id] = new Square(
					ctx,
					id,
					obstacle.obst,
					obstacle.x + gs.rWidthQrtr,
					-gs.obstacleWidth,
					gs.obstacleWidth,
					gs.obstacleWidth
				);
			}
		};
		const addFogParticle = () => {
			setTimeout(() => {
				addFogParticle();
			}, 1000 / gs.fogSettings.density);

			if (!gs.isRunning) return;

			let particleL = new FogParticle(
				ctx,
				gs.fogSettings.idx,
				gs.cars[0].x + gs.rWidthHalf,
				gs.cars[0].y + gs.cars[0].height
			);
			let particleR = new FogParticle(
				ctx,
				gs.fogSettings.idx + 1,
				gs.cars[1].x + gs.rWidthHalf,
				gs.cars[1].y + gs.cars[1].height
			);

			gs.fogParticles[particleL.id] = particleL;
			gs.fogParticles[particleR.id] = particleR;

			gs.fogSettings.idx += 2;
		};

		const reset = () => {
			gs.point = 0;
			gs.speed = 20;
			gs.spawnSpeed = 5;
			gs.spawnInterval = 1000;

			gs.startTime = 0;
			gs.pauseTime = 0;
			gs.resumeTime = 0;
			gs.endTime = 0;

			gs.cars = [];
			gs.circles = {};
			gs.squares = {};
			gs.fogParticles = {};
			gs.explosions = [];

			gs.cars.push(
				new Car(ctx, 0, true, carLeft, 0, gs.dprWindowHeight - gs.rWidthDouble, gs.rWidth, gs.rWidth)
			);
			gs.cars.push(
				new Car(
					ctx,
					1,
					false,
					carRight,
					gs.rWidthTriple,
					gs.dprWindowHeight - gs.rWidthDouble,
					gs.rWidth,
					gs.rWidth
				)
			);
		};

		const init = () => {
			if (gs.windowWidth > 700) {
				gs.windowWidth = 400;
			}

			canvas.width = gs.dprWindowWidth;
			canvas.height = gs.dprWindowHeight;

			canvas.style.width = `${gs.windowWidth}px`;
			canvas.style.height = `${gs.windowHeight}px`;

			gs.rWidth = parseInt(gs.dprWindowWidth / 4); //single road width

			gs.rWidthDouble = parseInt(gs.dprWindowWidth / 2); //double road width
			gs.rWidthTriple = gs.rWidthDouble + gs.rWidth; //tripple road width

			gs.rWidthHalf = gs.rWidth / 2; //half road width
			gs.rWidthQrtr = gs.rWidth / 4; //quarter road width

			gs.obstacleWidth = parseInt(gs.rWidthHalf);

			reset();
		};

		//Animation Loop
		const update = () => {
			requestAnimationFrame(update);

			// if (!gs.isRunning) return;

			for (let i in gs.cars) {
				gs.cars[i].update();
			}
			for (let i in gs.circles) {
				gs.circles[i].update();
			}
			for (let i in gs.squares) {
				gs.squares[i].update();
			}
			for (let i in gs.fogParticles) {
				gs.fogParticles[i].update();
			}
			for (let i in gs.explosions) {
				gs.explosions[i].update();
			}
		};
		const animate = () => {
			requestAnimationFrame(animate);

			ctx.clearRect(0, 0, canvas.width, canvas.height);

			ctx.beginPath();
			ctx.fillStyle = '#25337a';
			ctx.fillRect(0, 0, canvas.width, canvas.height);

			ctx.moveTo(gs.rWidth, 0);
			ctx.lineTo(gs.rWidth, canvas.height);
			ctx.moveTo(gs.rWidthDouble, 0);
			ctx.lineTo(gs.rWidthDouble, canvas.height);

			ctx.moveTo(gs.rWidthTriple, 0);
			ctx.lineTo(gs.rWidthTriple, canvas.height);

			ctx.strokeStyle = '#839bf3';
			ctx.lineWidth = 2;
			ctx.stroke();

			ctx.font = '100px Arial';
			ctx.fillStyle = 'white';
			ctx.textAlign = 'center';
			ctx.fillText(gs.point, canvas.width - 100, 150);
			ctx.fillText('II', 100, 150);

			if (gs.startTimer) ctx.fillText(gs.startTimer, canvas.width / 2, canvas.height / 2);

			ctx.closePath();

			for (let i in gs.cars) {
				gs.cars[i].draw();
			}
			for (let i in gs.circles) {
				gs.circles[i].draw();
			}
			for (let i in gs.squares) {
				gs.squares[i].draw();
			}
			for (let i in gs.fogParticles) {
				gs.fogParticles[i].draw();
			}
			for (let i in gs.explosions) {
				gs.explosions[i].draw();
			}
		};

		const startOrRestartGame = () => {
			if (gs.startTime !== 0 && gs.endTime === 0) return resumeGame();

			console.log('Starting Or Restarting Game');
			disableUI();
			reset();
			resumeGame();
			gs.startTime = new Date().getTime();
			gs.endTime = 0;
			enableUI();
		};

		//start
		init();
		update();
		animate();
		setTimeout(() => {
			addObstacle();
			addFogParticle();
		}, 1000);

		//Eevent Listeners
		canvas.addEventListener('touchstart', touchXY, false);
		startButtonRef.current.addEventListener('click', startOrRestartGame, false);
		window.addEventListener('blur', pauseGame, false);
	});

	return (
		<div>
			<Paper id="carsMenu" ref={menuRef} className={classes.menu}>
				<Title title={''} to={'/'} Icon={ArrowBackIcon} />
				<IconButton id="startButton" ref={startButtonRef} aria-label="delete" color="secondary">
					<ReplayIcon fontSize="large" />
				</IconButton>
			</Paper>
			<div id="elemnts" style={{ display: 'none' }}>
				<img className="obstacle" id="circle-left" src={CircleLeft} alt="Circle Left" />
				<img className="obstacle" id="circle-right" src={CircleRight} alt="Circe Right" />
				<img className="obstacle" id="square-left" src={SquareLeft} alt="Square Left" />
				<img className="obstacle" id="square-right" src={SquareRight} alt="square Right" />
				<img className="car" id="car-left" src={CarLeft} alt="Car Left" />
				<img className="car" id="car-right" src={CarRight} alt="Car Right" />
			</div>

			<canvas ref={canvasRef}></canvas>
		</div>
	);
}
