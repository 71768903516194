import React, { useState } from 'react';
import { IconButton, makeStyles } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';

import Title from '../../components/Title';

const useStyles = makeStyles((theme) => ({
	body: {
		paddingLeft: theme.spacing(4),
		paddingRight: theme.spacing(4),
	},
	formControl: {
		margin: theme.spacing(1),
		width: '100%',
	},
	whiteText: {
		color: 'white',
	},
}));

export default function ChainReactionStart({ setIsStarted, setgameConfig }) {
	const classes = useStyles();

	const [gridSize, setGridSize] = useState({ x: 6, y: 12 });
	const [players, setPlayers] = useState(2);

	const handleStartClick = (e) => {
		setgameConfig({ players, gridSize });
		setIsStarted(true);
	};

	const handleGridSizeChange = (e) => {
		setGridSize(JSON.parse(e.target.value));
	};

	const handlePlayersChange = (e) => {
		setPlayers(parseInt(e.target.value));
	};

	return (
		<div>
			<Title title={'Chain Reaction'} Icon={ArrowBackIcon} to={'/'} />

			<div className={classes.body}>
				<FormControl className={classes.formControl}>
					<InputLabel id="demo-simple-select-label" className={classes.whiteText}>
						Grid Size
					</InputLabel>
					<Select
						labelId="grid-size-select-label"
						id="grid-size-select"
						defaultValue={'{ x: 6, y: 12 }'}
						onChange={handleGridSizeChange}
						className={classes.whiteText}
					>
						<MenuItem value={'{ x: 6, y: 12 }'}>6 X 12</MenuItem>
					</Select>
				</FormControl>

				<FormControl className={classes.formControl}>
					<InputLabel id="demo-simple-select-label" className={classes.whiteText}>
						Players
					</InputLabel>
					<Select
						labelId="players-select-label"
						id="players-select"
						defaultValue={'2'}
						onChange={handlePlayersChange}
						className={classes.whiteText}
					>
						{[2, 3, 4, 5, 6, 7, 8].map((value) => (
							<MenuItem value={value}>{value}</MenuItem>
						))}
					</Select>
				</FormControl>

				<FormControl className={classes.formControl}>
					<IconButton id="startButton" color="secondary" onClick={handleStartClick}>
						<PlayCircleFilledIcon fontSize="large" />
					</IconButton>
				</FormControl>
			</div>
		</div>
	);
}
